import React, {useEffect} from 'react';
import {useAppDispatch} from "../../store/hooks";
import {
    createCategory,
    createEntry,
    deleteEntry,
    LivelogEntry,
    loadCategories,
    loadEntries,
    selectLivelogCategories,
    selectLivelogCategoriesLoading,
    selectLivelogEntries,
    selectLivelogEntriesLoading,
    selectLivelogFilter,
    updateFilter
} from "../../store/livelogSlice";
import {useSelector} from "react-redux";
import {useDialogs} from "@toolpad/core";
import {confirmOptions} from "../../shared/dialogs";
import dayjs from "dayjs";
import AddCategory from "./components/AddCategory";
import CreateEntry from "./components/CreateEntry";
import ShowEntries from "./components/ShowEntries";
import {FullpageSpinner} from "../../shared/Spinner";

const LivelogPage = () => {
    const dispatch = useAppDispatch();
    const dialog = useDialogs();
    const categories = useSelector(selectLivelogCategories);
    const filter = useSelector(selectLivelogFilter);
    const entries = useSelector(selectLivelogEntries);
    const entriesLoading = useSelector(selectLivelogEntriesLoading);
    const categoriesLoading = useSelector(selectLivelogCategoriesLoading);

    useEffect(() => {
        if (categoriesLoading === 'idle') {
            dispatch(loadCategories());
        }
    }, [categoriesLoading, dispatch]);

    useEffect(() => {
        if (entriesLoading === 'idle') {
            dispatch(loadEntries());
        }
    }, [entriesLoading, dispatch]);

    const createNewCategory = async (categoryName: string) => {
        const confirmation = await dialog.confirm(`Neue Kategorie ${categoryName} anlegen?`, confirmOptions);
        if (confirmation) {
            dispatch(createCategory(categoryName));
        }
    }

    const createNewEntry = async (entry: LivelogEntry) => {
        if (!entry.description) {
            await dialog.alert('Nicht alle Daten wurden angegeben!');
            return;
        }
        const confirmation = await dialog.confirm(`Neuen Eintrag ${entry.description} für ${dayjs(entry.date).format('DD.MM.YYYY')} in ${entry.category?.name} anlegen?`, confirmOptions);
        if (confirmation) {
            dispatch(createEntry(entry));
        }
    }

    const removeEntry = async (entry: LivelogEntry) => {
        const confirmation = await dialog.confirm(`Eintrag ${entry.description} wirklich löschen?`, confirmOptions);
        if (confirmation) {
            dispatch(deleteEntry(entry));
        }
    }

    const loadMore = () => {
        dispatch(updateFilter({...filter, page: filter.page + 1}));
        dispatch(loadEntries());
    }

    if (entriesLoading === 'pending' || categoriesLoading === 'pending') {
        return <FullpageSpinner/>
    }

    return <>
        <h1>Braglog</h1>
        {
            categories.length > 0
                ? <CreateEntry categories={categories} createEntry={(e) => createNewEntry(e)}/>
                : <></>
        }
        <ShowEntries entries={entries} removeEntry={(e) => removeEntry(e)} loadMore={() => loadMore()}/>
        <AddCategory createNewCategory={(name) => createNewCategory(name)}/>
    </>
}

export default LivelogPage;
