import React from 'react';
import {CircularProgress} from "@mui/material";
import styled from "@emotion/styled";

const SpinnerWrapper = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const FullpageSpinner = () => {
    return <SpinnerWrapper>
        <CircularProgress/>
    </SpinnerWrapper>
}
