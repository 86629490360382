import {Button, CardActions, CardHeader, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {FormCardContent} from "../../../shared/forms";
import {DatePicker} from "@mui/x-date-pickers";
import {SpacedCard} from "../../../layout/Cards";
import React, {useState} from "react";
import {Dayjs} from "dayjs";
import {LivelogCategory, LivelogEntry} from "../../../store/livelogSlice";

export type CreateEntryProps = {
    categories: LivelogCategory[];
    createEntry: (entry: LivelogEntry) => void;
}

const CreateEntry = ({categories, createEntry}: CreateEntryProps) => {
    const [description, setDescription] = useState('');
    const [date, setDate] = useState<Dayjs | null>(null);
    const [categoryId, setCategoryId] = useState<number>(categories[0].id!);

    const createNewEntry = () => {
        createEntry({
            description,
            date: date?.toDate() ?? new Date(),
            category: categories.find(c => c.id === categoryId) ?? categories[0]
        })
    }

    return <SpacedCard>
        <CardHeader title={'Neuer Eintrag'}/>
        <FormCardContent>
            <TextField sx={{mb: 2}} label={'Beschreibung'} value={description}
                       onChange={(e) => setDescription(e.target.value)}/>
            <DatePicker sx={{mb: 2}}
                        label="Datum"
                        format={'DD.MM.YYYY'}
                        value={date}
                        onChange={(newValue) => setDate(newValue)}
            />
            <FormControl>
                <InputLabel variant="outlined" htmlFor="grouped-select">
                    Kategorie
                </InputLabel>
                <Select
                    fullWidth={true}
                    label={'Kategorie'}
                    id="grouped-select"
                    value={categoryId}
                    onChange={e => setCategoryId(+e.target.value)}
                >
                    {
                        categories
                            .map(c => <MenuItem value={c.id} key={c.id}>{c.name}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </FormCardContent>
        <CardActions>
            <Button onClick={() => createNewEntry()}>Anlegen</Button>
        </CardActions>
    </SpacedCard>
}

export default CreateEntry;
