import React, {useEffect} from 'react';
import {useAppDispatch} from "../../store/hooks";
import {useSelector} from "react-redux";
import {
    Game,
    Goal,
    loadGame,
    Penalty,
    saveGame,
    selectCurrentGame,
    selectCurrentGameLoading,
    updateGame
} from "../../store/gameSlice";
import ShowGoal from "./components/ShowGoal";
import {useDialogs} from "@toolpad/core";
import {confirmOptions} from "../../shared/dialogs";
import GamePassedWarning from "./components/GamePassedWarning";
import GoalProgress from "./components/GoalProgress";
import {SpacedButton} from "../../shared/forms";
import {FullpageSpinner} from "../../shared/Spinner";

const GamePage = () => {
    const dispatch = useAppDispatch();
    const currentGame = useSelector(selectCurrentGame);
    const loading = useSelector(selectCurrentGameLoading);
    const dialog = useDialogs();

    useEffect(() => {
        if (loading === 'idle') {
            dispatch(loadGame())
        }
    }, [loading]);

    const togglePenalty = async (penalty: Penalty) => {
        const game: Game = JSON.parse(JSON.stringify(currentGame));

        const presentGoal = game
            .goals
            .filter(goal => !!goal.penalties.find(p => p.id === penalty.id))[0];
        const presentPenalty = presentGoal
            .penalties
            .find(p => p.id === penalty.id) as Penalty;

        const confirmed = await dialog.confirm(`${presentPenalty.cost} € als ${presentPenalty.done ? 'unfertig' : 'fertig'} markieren?`, confirmOptions)

        if (confirmed) {
            presentPenalty.done = !presentPenalty.done;
            dispatch(updateGame(game));
            dispatch(saveGame());
        }
    }

    const onGoalSave = (goal: Goal) => {
        const game: Game = JSON.parse(JSON.stringify(currentGame));
        game.goals = game.goals.map(g => g.id !== goal.id ? g : goal);
        dispatch(updateGame(game));
        dispatch(saveGame());
    }

    const addGoal = async () => {
        const confirmed = await dialog.confirm(`Soll ein neues Ziel hinzugefügt werden?`, confirmOptions)

        if (confirmed) {
            const game: Game = JSON.parse(JSON.stringify(currentGame));
            game.goals.push({penalties: [], name: 'Neues Ziel'});
            dispatch(updateGame(game));
            dispatch(saveGame());
        }
    }

    const deleteGoal = async (goal: Goal) => {
        const confirmed = await dialog.confirm(`Soll das Ziel ${goal.name} gelöscht werden?`, confirmOptions)

        if (confirmed) {
            const game: Game = JSON.parse(JSON.stringify(currentGame));
            const goals = game.goals.filter(g => g.id !== goal.id);
            dispatch(updateGame({...game, goals}));
            dispatch(saveGame());
        }
    }

    const completeGame = async () => {
        const confirmed = await dialog.confirm(`Soll die Woche ${currentGame?.calendarWeek} / ${currentGame?.calendarYear} abgeschlossen werden?`, confirmOptions)

        if (confirmed) {
            const game: Game = {...currentGame as Game, completed: true}
            dispatch(updateGame(game));
            dispatch(saveGame());
        }
    }

    if (currentGame && currentGame.goals.map && loading !== 'pending') {
        return <>
            <h2>Kalenderwoche {currentGame.calendarWeek} / {currentGame.calendarYear}{currentGame.completed ? ' (Abgeschlossen)' : ''}</h2>
            <GoalProgress goals={currentGame.goals}/>
            <GamePassedWarning currentGame={currentGame} completeGame={() => completeGame()}/>
            {
                currentGame.goals.map((goal, index) => <ShowGoal key={goal.id ?? `gu${index}`} goal={goal}
                                                                 readonly={currentGame.completed}
                                                                 onPenaltyToggle={(p) => togglePenalty(p)}
                                                                 onGoalSave={(g) => onGoalSave(g)}
                                                                 onGoalDelete={(g) => deleteGoal(g)}/>)
            }
            <div>
                <SpacedButton variant={'outlined'} color={'primary'} onClick={() => addGoal()}
                              disabled={currentGame.completed}>
                    Ziel hinzufügen
                </SpacedButton>
                <SpacedButton variant={'outlined'} color={'success'} onClick={() => completeGame()}
                              disabled={currentGame.completed}>
                    Abschließen
                </SpacedButton>
            </div>
        </>
    } else {
        return <FullpageSpinner/>
    }

}

export default GamePage;
