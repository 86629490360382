import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "./store/store";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Layout from "./layout/Layout";
import GamePage from "./pages/GamePage/GamePage";
import SummaryPage from "./pages/Summary/SummaryPage";
import LivelogPage from "./pages/Livelog/LivelogPage";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

// https://reactrouter.com/en/main/start/tutorial
const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout/>,
        children: [
            {
                path: 'game',
                element: <GamePage/>,
            },
            {
                path: 'summary',
                element: <SummaryPage/>,
            },
            {
                path: 'log',
                element: <LivelogPage/>,
            }
        ]
    }
])

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <RouterProvider router={router}/>
            </LocalizationProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
