import {Button, CardContent} from "@mui/material";
import styled from "@emotion/styled";

export const FormCardContent = styled(CardContent)`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    & > * {
        flex: 0 0 calc(50% - 0.5rem);
    }
`

export const SpacedButton = styled(Button)`
    margin-right: 1rem;
`
