import React, {useEffect, useState} from 'react';
import {useAppDispatch} from "../../store/hooks";
import {useSelector} from "react-redux";
import {
    Attribute,
    loadSummary,
    selectSummary,
    selectSummaryLoading,
    sendSummary,
    Summary,
    updateSummary
} from "../../store/summarySlice";
import {SpacedCard} from "../../layout/Cards";
import {Button, CardActions, CardContent, CardHeader, TextField} from "@mui/material";
import ModifyAttribute from "./components/ModifyAttribute";
import {useDialogs} from "@toolpad/core";
import {confirmOptions} from "../../shared/dialogs";
import {SpacedButton} from "../../shared/forms";
import {FullpageSpinner} from "../../shared/Spinner";

const SummaryPage = () => {
    const dispatch = useAppDispatch();
    const dialog = useDialogs();
    const summary = useSelector(selectSummary);
    const summaryLoading = useSelector(selectSummaryLoading);
    const [editing, setEditing] = useState(false);
    const [attribute, setAttribute] = useState('');

    useEffect(() => {
        if (summaryLoading === 'idle') {
            dispatch(loadSummary());
        }
    }, [summaryLoading, dispatch]);


    function updateAttribute(attribute: Attribute, xpDiff: number) {
        const newSummary = {
            ...summary,
            undistributedExperience: summary.undistributedExperience - xpDiff,
            attributes: summary.attributes.map(attr => (attr.id === attribute.id) ? ({
                ...attr,
                experience: attr.experience + xpDiff
            }) : attr)
        };
        setEditing(true);
        dispatch(updateSummary(newSummary));
    }

    async function createNewAttribute() {
        const confirmation = await dialog.confirm(`Neues Eigenschaft ${attribute} anlegen?`, confirmOptions);
        if (confirmation) {
            const newSummary: Summary = {
                ...summary,
                attributes: [...summary.attributes, {experience: 0, name: attribute}]
            };
            dispatch(updateSummary(newSummary));
            dispatch(sendSummary());
        }
    }

    async function saveAttributes() {
        const confirmation = await dialog.confirm(`Möchtest du deine Änderungen speichern?`, confirmOptions);
        if (confirmation) {
            dispatch(sendSummary());
        }
    }

    if (summaryLoading === 'pending') {
        return <FullpageSpinner/>
    }

    return <div>
        <SpacedCard>
            <CardHeader title={'Aktuelle Zusammenfassung' + (editing ? ' *' : '')}/>
            <CardContent>
                <div>{summary.undistributedExperience} / {summary.totalExperience} Erfahrungspunkte verfügbar</div>
                {
                    summary.attributes.map(attribute => <ModifyAttribute attribute={attribute}
                                                                         onExperienceChange={xpDiff => updateAttribute(attribute, xpDiff)}/>)
                }
            </CardContent>
            <CardActions>
                <SpacedButton variant={'outlined'} color={'success'} onClick={saveAttributes} disabled={!editing}>
                    Speichern
                </SpacedButton>
            </CardActions>
        </SpacedCard>
        <SpacedCard>
            <CardHeader title={'Neue Eigenschaft anlegen'}/>
            <CardContent>
                <TextField label={'Neue Eigenschaft'} value={attribute} onChange={e => setAttribute(e.target.value)}/>
            </CardContent>
            <CardActions>
                <Button onClick={createNewAttribute}>Eigenschaft anlegen</Button>
            </CardActions>
        </SpacedCard>
    </div>
}

export default SummaryPage;
