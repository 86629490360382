import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export type Attribute = {
    name: string;
    experience: number;
    id?: number;
};

export type Summary = {
    id?: number;
    totalExperience: number;
    undistributedExperience: number;
    attributes: Attribute[];
}

interface SummaryState {
    summary: Summary;
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: SummaryState = {
    summary: {
        attributes: [],
        totalExperience: 0,
        undistributedExperience: 0,
    },
    loading: 'idle',
}


export const loadSummary = createAsyncThunk(
    'summary/loadSummary',
    async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/summary`, {credentials: 'include'});
        return (await response.json()) as Summary;
    }
)

export const sendSummary = createAsyncThunk<Summary, void, { state: RootState }>(
    'summary/updateSummary',
    async (_, thunkAPI) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/summary`, {
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(thunkAPI.getState().summary.summary)
        });
        return (await response.json()) as Summary;
    }
);

export const summarySlice = createSlice({
    name: 'summary',
    initialState,
    reducers: {
        updateSummary(state: SummaryState, action: PayloadAction<Summary>) {
            state.summary = action.payload;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadSummary.fulfilled, (state: SummaryState, action: PayloadAction<Summary>) => {
            state.summary = action.payload;
            state.loading = 'succeeded';
        });
        builder.addCase(loadSummary.pending, (state: SummaryState) => {
            state.loading = 'pending';
        });
        builder.addCase(sendSummary.fulfilled, (state: SummaryState, action: PayloadAction<Summary>) => {
            state.summary = action.payload;
            state.loading = 'succeeded';
        });
        builder.addCase(sendSummary.pending, (state: SummaryState) => {
            state.loading = 'pending';
        });
    }
});

export const {updateSummary} = summarySlice.actions;
export const selectSummary = (state: RootState) => state.summary.summary;
export const selectSummaryLoading = (state: RootState) => state.summary.loading;
export default summarySlice.reducer;
