import {Goal} from "../../../store/gameSlice";
import {Box, LinearProgress, Typography} from "@mui/material";
import React from "react";

const GoalProgress = (props: { goals: Goal[] }) => {
    const addUp = (p: number, c: number) => p + c;
    const current = props.goals.map(goal => goal.penalties.reduce((p, c) => p + (c.done ? c.cost : 0), 0)).reduce(addUp, 0);
    const max = props.goals.map(goal => goal.penalties.reduce((p, c) => p + c.cost, 0)).reduce(addUp, 0);
    const percentage = current * 100 / max;
    return (
        <Box sx={{display: 'flex', alignItems: 'center', mb: 4}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" value={percentage}/>
            </Box>
            <Box sx={{minWidth: 90, textAlign: 'right'}}>
                <Typography
                    variant="body2"
                    sx={{color: 'text.secondary'}}
                >{`${current} € / ${max} €`}</Typography>
            </Box>
        </Box>
    );
};

export default GoalProgress;
