import React, {useState} from "react";
import {SpacedCard} from "../../../layout/Cards";
import {Button, CardActions, CardContent, CardHeader} from "@mui/material";
import dayjs from "dayjs";
import {Game} from "../../../store/gameSlice";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekday from "dayjs/plugin/weekday";
import de from "dayjs/locale/de"

dayjs.locale(de);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);

export type GamePassedWarningProps = {
    currentGame: Game;
    completeGame: () => void;
}

const GamePassedWarning = ({completeGame, currentGame}: GamePassedWarningProps) => {
    const [hideWarning, setHideWarning] = useState(false);

    const calendarWeek = dayjs().week();
    const calendarYear = dayjs().year();

    const gameIsInPast = calendarYear > currentGame.calendarYear || calendarWeek > currentGame.calendarWeek;


    return <>
        {
            gameIsInPast && !hideWarning
                ? <SpacedCard>
                    <CardHeader title={'Aktuelles Spiel liegt in der Vergangenheit'}/>
                    <CardContent>
                        <div>Willst du das aktuelle Spiel abschließen?</div>
                    </CardContent>
                    <CardActions>
                        <Button variant={'outlined'} color={'primary'}
                                onClick={() => setHideWarning(true)}>Ignorieren</Button>
                        <Button variant={'contained'} color={'primary'}
                                onClick={() => completeGame()}>Abschließen</Button>
                    </CardActions>
                </SpacedCard>
                : <></>
        }
    </>
}

export default GamePassedWarning;
