import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Button,
    CardActions,
    CardHeader,
    createTheme,
    CssBaseline,
    TextField,
    ThemeProvider
} from "@mui/material";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ListIcon from '@mui/icons-material/List';
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch} from "../store/hooks";
import {useSelector} from "react-redux";
import {login, selectUser} from "../store/userSlice";
import styled from "@emotion/styled";
import {DialogsProvider} from "@toolpad/core";
import {SpacedCard} from "./Cards";
import {FormCardContent} from "../shared/forms";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &::-webkit-scrollbar {
        display: none;
    }

    & {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
`

const ContentWrapper = styled.main`
    display: block;
    max-width: 920px;
    width: 100%;
    height: 100%;
    padding: 1rem 1rem 80px;
`

const Layout = () => {
    const dispatch = useAppDispatch();
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const firstPathPart = useLocation().pathname.split('/')[1];

    const [value, setValue] = useState(!!firstPathPart ? firstPathPart : 'game');

    useEffect(() => {
        if (user) {
            navigate(value);
        } else {
            dispatch(login());
        }
    }, [user]);

    return <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
        <DialogsProvider>
            {!!user
                ? <AppWrapper>
                    <ContentWrapper>
                        <Outlet/>
                    </ContentWrapper>
                    <Box sx={{width: '100%', position: 'fixed', bottom: 0, left: 0, zIndex: 1}}>
                        <BottomNavigation
                            showLabels
                            value={value}
                            onChange={(event, newValue) => {
                                console.log(newValue);
                                setValue(newValue);
                            }}
                        >
                            <BottomNavigationAction component={Link} to={'game'} icon={<SportsEsportsIcon/>}
                                                    value={'game'}/>
                            <BottomNavigationAction component={Link} to={'summary'} icon={<ListIcon/>}
                                                    value={'summary'}/>
                            <BottomNavigationAction component={Link} to={'log'} icon={<FavoriteIcon/>} value={'log'}/>
                        </BottomNavigation>
                    </Box>
                </AppWrapper>
                : <SpacedCard>
                    <CardHeader title={'Bitte einloggen'}/>
                    <FormCardContent>
                        <TextField label={'Username'} value={username} onChange={(e) => setUsername(e.target.value)}/>
                        <TextField label={'Password'} value={password} onChange={(e) => setPassword(e.target.value)}
                                   type={'password'}/>
                    </FormCardContent>
                    <CardActions>
                        <Button onClick={() => dispatch(login({username, password}))}>Einloggen</Button>
                    </CardActions>
                </SpacedCard>}
        </DialogsProvider>
    </ThemeProvider>;
}


export default Layout;
