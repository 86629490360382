import React, {useEffect, useState} from 'react';
import {Goal, Penalty} from "../../../store/gameSlice";
import {CardActions, CardContent, CardHeader, FormControlLabel, IconButton, Switch, TextField} from "@mui/material";
import {SpacedCard} from "../../../layout/Cards";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import styled from "@emotion/styled";

const SwitchFormControlWrapper = styled(FormControlLabel)`
    margin-left: 0;
    margin-right: 1.25rem;
`

const ShowGoal = ({goal, onPenaltyToggle, onGoalSave, onGoalDelete, readonly}: {
    goal: Goal,
    onPenaltyToggle: (penalty: Penalty) => void,
    onGoalSave: (goal: Goal) => void,
    onGoalDelete: (goal: Goal) => void,
    readonly: boolean
}) => {
    const [goalToEdit, setGoalToEdit] = useState<Goal | undefined>(undefined);

    const enterEditMode = () => {
        console.log('enter edit');
        setGoalToEdit(JSON.parse(JSON.stringify(goal)) as Goal);
    }

    const closeEditMode = (save: boolean) => {
        if (save && goalToEdit) {
            onGoalSave(goalToEdit);
        }
        setGoalToEdit(undefined);
    }

    // TODO: by index would be better
    const updatePenalty = (value: string, penalty: Penalty) => {
        try {
            const goal = {
                ...goalToEdit!,
                penalties: goalToEdit!.penalties.map(p => p.id !== penalty.id ? p : {...p, cost: +value})
            };
            setGoalToEdit(goal);
        } catch {
            return;
        }
    }

    const addPenalty = () => {
        const goal = {...goalToEdit!, penalties: [...goalToEdit!.penalties, {cost: 0, done: false}]};
        setGoalToEdit(goal);
    }

    const removePenalty = () => {
        const goal = {
            ...goalToEdit!,
            penalties: goalToEdit!.penalties.filter((_, index) => index !== goalToEdit!.penalties.length - 1)
        }
        setGoalToEdit(goal);
    }

    useEffect(() => {
        if (goal.penalties.length === 0 && !goalToEdit) {
            enterEditMode();
        }
    }, [goalToEdit]);

    if (!goalToEdit) {
        return <SpacedCard>
            <CardHeader title={goal.name}/>
            <CardContent>
                {goal.penalties.map((penalty, index) => <SwitchFormControlWrapper key={penalty.id ?? `pu${index}`}
                                                                                  labelPlacement={'start'}
                                                                                  control={<Switch disabled={readonly}
                                                                                                   checked={penalty.done}
                                                                                                   onChange={() => onPenaltyToggle(penalty)}/>}
                                                                                  label={`${penalty.cost} €`}/>)}
            </CardContent>
            <CardActions>
                <IconButton disabled={readonly} onClick={() => onGoalDelete(goal)}
                            color={'error'}><RemoveIcon/></IconButton>
                <IconButton disabled={readonly} onClick={() => enterEditMode()}
                            color={'primary'}><EditIcon/></IconButton>
            </CardActions>
        </SpacedCard>
    } else {
        return <SpacedCard>
            <CardHeader title={<TextField label={'Name des Ziels'} value={goalToEdit.name}
                                          onChange={e => setGoalToEdit({...goalToEdit, name: e.target.value})}/>}/>
            <CardContent>
                {goalToEdit.penalties.map((penalty, index) => <TextField key={penalty.id ?? `pu${index}`}
                                                                         value={penalty.cost}
                                                                         onChange={(e) => updatePenalty(e.target.value, penalty)}/>)}
            </CardContent>
            <CardActions>
                <IconButton disabled={readonly} onClick={() => removePenalty()}
                            color={'info'}><RemoveIcon/></IconButton>
                <IconButton disabled={readonly} onClick={() => addPenalty()} color={'info'}><AddIcon/></IconButton>
                <IconButton disabled={readonly} onClick={() => closeEditMode(false)}
                            color={'error'}><CancelIcon/></IconButton>
                <IconButton disabled={readonly} onClick={() => closeEditMode(true)}
                            color={'success'}><SaveIcon/></IconButton>
            </CardActions>
        </SpacedCard>
    }
}

export default ShowGoal;
