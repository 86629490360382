import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export type LivelogCategory = {
    id?: number;
    name: string;
}

export type LivelogEntry = {
    id?: number;
    description: string;
    category: LivelogCategory;
    date: Date
}

export type LivelogFilter = {
    categoryId?: number;
    page: number;
    pageSize: number;
}

interface LivelogState {
    entries: LivelogEntry[];
    categories: LivelogCategory[];
    categoriesLoading: 'idle' | 'pending' | 'succeeded' | 'failed';
    entriesLoading: 'idle' | 'pending' | 'succeeded' | 'failed';
    filter: LivelogFilter;
}

const initialState: LivelogState = {
    categories: [],
    entries: [],
    categoriesLoading: 'idle',
    entriesLoading: 'idle',
    filter: {page: 1, pageSize: 10},

}

export const loadCategories = createAsyncThunk(
    'livelog/loadCategories',
    async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/livelog/category`, {credentials: 'include'});
        return (await response.json()) as LivelogCategory[];
    }
)

export const createCategory = createAsyncThunk(
    'livelog/createCategory',
    async (name: string) => {
        const category: LivelogCategory = {name};
        const response = await fetch(`${process.env.REACT_APP_API_URL}/livelog/category`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(category)
        });
        return (await response.json()) as LivelogCategory;
    }
)

export const loadEntries = createAsyncThunk<LivelogEntry[], void, { state: RootState }>(
    'livelog/loadEntries',
    async (_, thunkAPI) => {
        const filter = thunkAPI.getState().livelog.filter;
        const filterQuery = `?pageSize=${filter.pageSize}&page=${filter.page}${filter.categoryId ? '&category' + filter.categoryId : ''}`;
        const response = await fetch(`${process.env.REACT_APP_API_URL}/livelog${filterQuery}`, {
            credentials: 'include',
            method: 'GET',
        });
        return (await response.json()) as LivelogEntry[];
    }
)

export const createEntry = createAsyncThunk(
    'livelog/createEntry',
    async (entry: LivelogEntry) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/livelog`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(entry)
        });
        return (await response.json()) as LivelogEntry[];
    }
)

export const deleteEntry = createAsyncThunk(
    'livelog/deleteEntry',
    async (entry: LivelogEntry) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/livelog/${entry.id}`, {
            credentials: 'include',
            method: 'DELETE',
        });
        return (await response.text().then(() => entry.id));
    }
)

export const livelogSlice = createSlice({
    name: 'livelog',
    initialState,
    reducers: {
        updateFilter(state: LivelogState, action: PayloadAction<LivelogFilter>) {
            state.filter = action.payload;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadCategories.pending, (state: LivelogState) => {
            state.categoriesLoading = 'pending';
        });
        builder.addCase(loadCategories.fulfilled, (state: LivelogState, action: PayloadAction<LivelogCategory[]>) => {
            state.categories = action.payload.sort((a, b) => a.name < b.name ? -1 : 1);
            state.categoriesLoading = "succeeded";
        });
        builder.addCase(createCategory.pending, (state: LivelogState) => {
            state.categoriesLoading = 'pending';
        });
        builder.addCase(createCategory.fulfilled, (state: LivelogState, action: PayloadAction<LivelogCategory>) => {
            state.categories.push(action.payload);
            state.categoriesLoading = "succeeded";
        });
        builder.addCase(loadEntries.pending, (state: LivelogState) => {
            state.entriesLoading = 'pending';
        });
        builder.addCase(loadEntries.fulfilled, (state: LivelogState, action: PayloadAction<LivelogEntry[]>) => {
            state.entries.push(...action.payload.filter(entry => !state.entries.find(e => e.id === entry.id)));
            state.entriesLoading = 'succeeded';
        });
        builder.addCase(createEntry.pending, (state: LivelogState) => {
            state.entriesLoading = 'pending';
        });
        builder.addCase(createEntry.fulfilled, (state: LivelogState, action: PayloadAction<LivelogEntry>) => {
            state.entriesLoading = 'succeeded';
            state.entries.push(action.payload);
        });
        builder.addCase(deleteEntry.pending, (state: LivelogState) => {
            state.entriesLoading = 'pending';
        });
        builder.addCase(deleteEntry.fulfilled, (state: LivelogState, action: PayloadAction<number>) => {
            state.entriesLoading = 'succeeded';
            state.entries = state.entries.filter(e => e.id !== action.payload);
        });
    }
})

export const {updateFilter} = livelogSlice.actions;
export const selectLivelogCategories = (state: RootState) => state.livelog.categories;
export const selectLivelogEntries = (state: RootState) => state.livelog.entries;
export const selectLivelogFilter = (state: RootState) => state.livelog.filter;
export const selectLivelogCategoriesLoading = (state: RootState) => state.livelog.categoriesLoading;
export const selectLivelogEntriesLoading = (state: RootState) => state.livelog.entriesLoading;
export default livelogSlice.reducer;
