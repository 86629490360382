import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export type Penalty = {
    id?: number;
    cost: number;
    done: boolean;
}

export type Goal = {
    id?: number;
    name: string;
    penalties: Penalty[];
}

export type Game = {
    id: number
    calendarYear: number;
    calendarWeek: number;
    completed: boolean;
    goals: Goal[];
}

interface GameState {
    currentGame: Game | undefined;
    // TODO: extract me!
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: GameState = {
    currentGame: undefined,
    loading: 'idle',
}

export const loadGame = createAsyncThunk(
    'game/loadCurrent',
    async (arg, thunkAPI) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/game`, {credentials: 'include'});
        return (await response.json()) as Game;
    }
);

export const saveGame = createAsyncThunk(
    'game/save',
    async (_, thunkApi) => {
        const game = (thunkApi.getState() as RootState).game.currentGame as Game;
        const response = await fetch(`${process.env.REACT_APP_API_URL}/game/${game.id}`, {
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(game)
        });
        return (await response.json()) as Game;
    }
)

export const gameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        updateGame: (state: GameState, action: PayloadAction<Game>) => {
            state.currentGame = action.payload;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadGame.fulfilled, (state: GameState, payload: PayloadAction<Game>) => {
            state.currentGame = payload.payload;
            state.loading = 'succeeded';
        });
        builder.addCase(loadGame.pending, (state: GameState) => {
            state.loading = 'pending'
        });
        builder.addCase(saveGame.fulfilled, (state: GameState, payload: PayloadAction<Game>) => {
            state.currentGame = payload.payload;
            state.loading = 'succeeded';
        });
        builder.addCase(saveGame.pending, (state: GameState) => {
            state.loading = 'pending'
        });
    }
});

export const {updateGame} = gameSlice.actions;
export const selectCurrentGame = (state: RootState) => state.game.currentGame;
export const selectCurrentGameLoading = (state: RootState) => state.game.loading;
export default gameSlice.reducer;
