import {SpacedCard} from "../../../layout/Cards";
import {Button, CardActions, CardHeader, TextField} from "@mui/material";
import React, {useState} from "react";
import {FormCardContent} from "../../../shared/forms";

export type AddCategoryProps = {
    createNewCategory: (categoryName: string) => void;
}

const AddCategory = ({createNewCategory}: AddCategoryProps) => {
    const [newCategory, setNewCategory] = useState('');

    const triggerCreation = () => {
        createNewCategory(newCategory);
        setNewCategory('');
    }

    return <SpacedCard>
        <CardHeader title={'Neue Kategorie erstellen'}>
        </CardHeader>
        <FormCardContent>
            <TextField label={'Neue Kategorie'} value={newCategory}
                       onChange={(e) => setNewCategory(e.target.value)}/>

        </FormCardContent>
        <CardActions>
            <Button onClick={() => triggerCreation()}>Anlegen</Button>
        </CardActions>
    </SpacedCard>
}

export default AddCategory;
