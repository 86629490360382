import {configureStore} from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import gameReducer from "./gameSlice";
import livelogReducer from "./livelogSlice";
import summaryReducer from "./summarySlice";

export const store = configureStore({
    reducer: {
        user: userReducer,
        game: gameReducer,
        livelog: livelogReducer,
        summary: summaryReducer
    },
    preloadedState: {}
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
