import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import {SpacedCard} from "../../../layout/Cards";
import React from "react";
import {LivelogEntry} from "../../../store/livelogSlice";

export type ShowEntriesProps = {
    entries: LivelogEntry[];
    removeEntry: (e: LivelogEntry) => void;
    loadMore: () => void;
}

const ShowEntries = ({entries, loadMore, removeEntry}: ShowEntriesProps) => {
    return <SpacedCard>
        <CardHeader title={'Bisherige Einträge'}/>
        <CardContent>
            <List>
                {entries.map((entry) => {
                    return <ListItem key={entry.id}>
                        <ListItemIcon>
                            <IconButton aria-label="delete" onClick={() => removeEntry(entry)}>
                                <DeleteIcon/>
                            </IconButton>
                        </ListItemIcon>
                        <ListItemText primary={entry.description}
                                      secondary={`${dayjs(entry.date).format('DD.MM.YYYY')} - ${entry.category.name}`}/>
                    </ListItem>
                })}
            </List>
        </CardContent>
        <CardActions>
            <Button onClick={() => loadMore()} color={'primary'}>Mehr
                laden</Button>
        </CardActions>
    </SpacedCard>
}

export default ShowEntries;
