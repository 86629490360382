import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

interface UserState {
    user: string | undefined;
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: UserState = {
    user: undefined,
    loading: "idle",
}

export const login = createAsyncThunk(
    'user/login',
    async (credentials?: { username: string, password: string }) => {
        if (!!credentials) {
            const basicAuthString = 'Basic ' + btoa(credentials?.username + ":" + credentials?.password);
            const headers = {'Authorization': basicAuthString};
            const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {credentials: 'include', headers})
            return (await response.json()) as { user: string }
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {credentials: 'include'})
        return (await response.json()) as { user: string }
    },
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(login.fulfilled, (state: UserState, payload: PayloadAction<string | undefined>) => {
            state.user = payload.payload;
            state.loading = 'succeeded';
        });
        builder.addCase(login.pending, (state: UserState) => {
            state.loading = 'pending';
        })
    }
})

export const selectUser = (state: RootState) => state.user.user;
export default userSlice.reducer;
