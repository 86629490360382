import {Attribute} from "../../../store/summarySlice";
import styled from "@emotion/styled";
import {Button} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

let lowerLevelBoarders: number[] = [];

export const calculateLevel = (xp: number) => {
    if (xp === 0) {
        return 0;
    }
    if (lowerLevelBoarders.length === 0) {
        const levels = [1, 2, ...new Array(98).fill(-1)];
        for (let i = 2; i < levels.length; i++) {
            levels[i] = levels[i - 1] + levels [i - 2];
        }
        lowerLevelBoarders = levels.map(l => l * 50);
        console.log(lowerLevelBoarders);
    }
    return lowerLevelBoarders.findIndex(l => l >= xp) + 1;
}

export type AttributeProps = {
    attribute: Attribute;
    onExperienceChange: (experience: number) => void;
}

const AttributeWrapper = styled.div`

`

const AttributeName = styled.div`
    font-weight: 400;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
`

const AttributeExperience = styled.span`
    margin: 0 1rem;
    font-weight: bold;
`

const ModifyAttribute = ({attribute, onExperienceChange}: AttributeProps) => {
    return <AttributeWrapper>
        <AttributeName>{attribute.name} - Lvl. {calculateLevel(attribute.experience)}</AttributeName>
        <Button startIcon={<RemoveIcon/>} onClick={() => onExperienceChange(-5)}>
            5
        </Button>
        <Button startIcon={<RemoveIcon/>} onClick={() => onExperienceChange(-1)}>
            1
        </Button>
        <AttributeExperience>{attribute.experience}</AttributeExperience>
        <Button startIcon={<AddIcon/>} onClick={() => onExperienceChange(1)}>
            1
        </Button>
        <Button startIcon={<AddIcon/>} onClick={() => onExperienceChange(5)}>
            5
        </Button>
    </AttributeWrapper>
}

export default ModifyAttribute;

